<template>
  <component :is="tag" v-click-outside="handle">
    <slot />
  </component>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import HasWrapper from './HasWrapper';

export default {
  mixins: [HasWrapper],

  directives: { ClickOutside },

  props: {
    handler: { required: true, type: Function }
  },

  methods: {
    handle() {
      this.handler();
    }
  }
};
</script>
