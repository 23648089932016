<template>
  <div>
    <slot :filters="filters" :hasMore="hasMore" :isLoading="isLoading" :results="results" />
  </div>
</template>

<script>
const queryString = require('query-string');
import pick from 'lodash/pick';

const parseQuery = () => queryString.parse(window.location.search, { arrayFormat: 'bracket' });
const stringify = (params) => queryString.stringify(params, { arrayFormat: 'bracket' });

export default {
  props: {
    url: { required: true, type: String },
    hiddenInputs: { required: false, type: Object, default: () => ({}) },
    resultsSelector: { required: true, type: String },
    itemSelector: { required: true, type: String },
    defaultFilters: {
      required: false,
      type: Object,
      default: () => ({
        take: 12,
        categories: []
      })
    },
    stateFilters: { required: false, type: Array, default: () => ['take', 'categories'] }
  },

  data() {
    const defaults = this.defaultFilters;

    return {
      isLoading: false,
      results: null,
      hasMore: true,
      filters: {
        take: defaults.take,
        page: 1,
        categories: defaults.categories,
        ...this.hiddenInputs
      },
      recentlyWasNavigated: false
    };
  },

  watch: {
    'filters.page'() {
      this.loadResults();
    },
    'filters.take'() {
      this.handleFilterChanged();
    },
    'filters.categories'() {
      this.handleFilterChanged();
    }
  },

  created() {
    // Load any prerendered results in memory
    const el = document.querySelector(this.resultsSelector);
    this.results = el.innerHTML;

    this.$nextTick(() => this.updateHasMore());

    this.loadStateFromQuery();
    this.monitorStateInQuery();
  },

  computed: {
    fetchUrl() {
      return `${this.url}?${stringify(this.filters)}`;
    }
  },

  methods: {
    async loadResults() {
      // Update URL state
      this.pushStateToQuery();

      // Mark as loading
      this.isLoading = true;

      // Fetch the results and insert/append them
      const html = (await this.fetch()).trim();
      this.results = (this.filters.page === 1 ? '' : this.results) + html;

      // Update load more button
      this.$nextTick(() => this.updateHasMore());

      // Remove the loading
      this.isLoading = false;
    },

    async fetch() {
      const response = await fetch(this.fetchUrl, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      return await response.text();
    },

    handleFilterChanged() {
      if (this.filters.page > 1) {
        this.filters.page = 1;
      } else {
        this.loadResults();
      }
    },

    updateHasMore() {
      const items = this.$el.querySelectorAll(`${this.resultsSelector} ${this.itemSelector}`);

      // Guess if there's more items on the server
      this.hasMore = items.length === this.filters.page * this.filters.take;
    },

    loadStateFromQuery() {
      const params = pick(parseQuery(), this.stateFilters);

      if (typeof params.categories !== 'undefined') {
        this.filters.categories = params.categories;
      }
      if (typeof params.take !== 'undefined') {
        this.filters.take = parseInt(params.take);
      }
    },

    pushStateToQuery() {
      // If recentlyWasNavigated flag is up, the state change was triggered,
      // via a browser navigation button and we should no publish the state again
      if (this.recentlyWasNavigated) {
        this.recentlyWasNavigated = false;
      } else {
        const parameters = pick(this.filters, this.stateFilters);
        const stateUrl = `${this.url}?${stringify(parameters)}`;

        window.history.pushState({}, null, stateUrl);
      }
    },

    monitorStateInQuery() {
      const listener = () => {
        if (!window.location.pathname.startsWith(this.url)) {
          // Out of the scope of this page, stop listening
          return window.removeEventListener('popstate', listener);
        }

        this.recentlyWasNavigated = true;
        this.loadStateFromQuery();
      };
      window.addEventListener('popstate', listener);
    }
  }
};
</script>

<style></style>
