<script>
export default {
  props: {
    watch: { required: true },
    toBoolean: { required: false, type: Function, default: (val) => !!val }
  },

  render() {
    return null;
  },

  watch: {
    watch: {
      deep: true,
      handler(newValue, oldValue) {
        const payload = { newValue, oldValue, document: window.document, window };

        this.$emit('change', payload);
        if (!!this.toBoolean(newValue)) this.$emit('on', payload);
        if (!this.toBoolean(newValue)) this.$emit('off', payload);
      }
    }
  }
};
</script>
