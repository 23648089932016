import Vue from 'vue';
import { KeepRatio } from '../application/javascript/keep-ratio';

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
// require('@rails/activestorage').start();
// require('channels');

// Load the FE styles
require('../application/styles/main.scss');
// Make sure webpack is aware of all FE images
require.context('../application/images', true);

// Load Vue components
require('../application/components/index');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Bootstrap all public admin goodies
require('../admin/public/bootstrap');

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  window._vue = new Vue({ el: '#js-wrapper' });

  const embed = document.querySelector('#embed-video iframe');
  if (embed) {
    KeepRatio.init(embed);
  }
});
