import Vue from 'vue';

import ClickAway from './higher-order/ClickAway';
import HasState from './higher-order/HasState';
import Watcher from './higher-order/Watcher';

import CookiesNotice from './CookiesNotice';
import FactsList from './facts/FactsList';

Vue.component('click-away', ClickAway);
Vue.component('has-state', HasState);
Vue.component('watcher', Watcher);

Vue.component('cookies-notice', CookiesNotice);
Vue.component('facts-list', FactsList);

Vue.component('stream-countdown', () => import('./weremember-events/StreamCountdown'));
