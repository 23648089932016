<template>
  <component :is="tag">
    <slot :state="stateData" :setState="set" />
  </component>
</template>

<script>
import HasWrapper from './HasWrapper';

export default {
  mixins: [HasWrapper],

  props: {
    state: { required: true, type: Object }
  },

  data() {
    return {
      stateData: this.state
    };
  },

  methods: {
    set(property, value) {
      this.$set(this.stateData, property, value);
    }
  }
};
</script>
