export class KeepRatio {
  constructor(el) {
    this._el = typeof el === 'string' ? document.querySelector(el) : el;

    if (!this._el || !this._el.hasAttribute('width') || !this._el.hasAttribute('height')) {
      throw 'Bad element';
    }

    this._ratioRectangle = new RatioRectangle(
      parseInt(this._el.getAttribute('width')),
      parseInt(this._el.getAttribute('height'))
    );
  }

  static init(el) {
    return new KeepRatio(el).keep();
  }

  get ratio() {
    return this._ratioRectangle.ratio;
  }

  keep() {
    this._el.classList.add('keep-ratio-el');
    this._el.parentNode.classList.add('keep-ratio');
    this._el.parentNode.style.paddingTop = `${this.ratio * 100}%`;

    this.applyWidth();
  }

  applyWidth() {
    const targetRectangle = Viewport.get().fit(this._ratioRectangle);

    this._el.parentNode.style.width = `${targetRectangle.width}px`;

    window.addEventListener('resize', () => this.applyWidth(), { once: true });
  }
}

class RatioRectangle {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }

  get ratio() {
    return (this.height * 1.0) / this.width;
  }

  changeWidth(width) {
    return new RatioRectangle(width, this.ratio * width);
  }

  changeHeight(height) {
    return new RatioRectangle((height * 1.0) / this.ratio, height);
  }
}

class Viewport {
  constructor(safeArea = 0.9) {
    this.width = window.visualViewport.width;
    this.height = window.visualViewport.height;
    this.safeArea = safeArea;
  }

  static get(safeArea = 0.9) {
    return new Viewport(safeArea);
  }

  get maxWidth() {
    return this.width * this.safeArea;
  }

  get maxHeight() {
    return this.height * this.safeArea;
  }

  fit(rectangle) {
    rectangle = rectangle.changeWidth(this.maxWidth);

    if (!this.doesFit(rectangle)) {
      rectangle = rectangle.changeHeight(this.maxHeight);
    }

    return rectangle;
  }

  doesFit(rectangle) {
    return this.maxWidth >= rectangle.width && this.maxHeight >= rectangle.height;
  }
}
