import Vue from 'vue';

Vue.component('settings-panel', () =>
  import(/* webpackChunkName: "admin-settings-panel" */ './settings-panel/SettingsPanel')
);

// Statics - Editable texts
require('../statics/index');
// DeepLink
require('../deep-links/index');
